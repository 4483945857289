import React, { useEffect, useRef, useState, useCallback } from 'react'
import useMedia from 'use-media'

import styles from '@styles/pages/company.module.scss'
import Layout from "@comp/layout"
import SEO from '@comp/seo'

import PageTitle from '@comp/page_title'

import { breakpoint } from '@const/config'

// markup
const CompanyPage = ({ location }) => {
  const [minH, setMinH] = useState('550px')
  const innerOffset = '50';
  const map = useRef()
  const inner = useRef()
  const isWide = useMedia({
    minWidth: `${breakpoint.pcWide}px`
  })
  const onRezise = useCallback(() => {
    if (inner.current) {
      setMinH(`${parseInt(inner.current.clientHeight, 10) + parseInt(innerOffset, 10)}px`)
    }
  }, [])

  useEffect(() => {
    onRezise();
    if (isWide) {
      window.addEventListener('resize', onRezise)
    } else {
      onRezise();
      window.removeEventListener('resize', onRezise);
    }
    return () => {
      window.removeEventListener('resize', onRezise);
    }
  }, [isWide, minH, onRezise])

  return (
    <Layout location={location} pageName="company">
      <SEO
        title={`会社概要`}
        description={`MIRAI SAKE COMPANYの会社概要はこちらをご覧ください。`}
        path={location.pathname}
      />
      <main className={styles.container} style={{ '--minH': minH }}>
        <div className={styles.inner}>
          <div ref={inner} className={styles.box}>
            <PageTitle
              label='会社概要'
              labelEn='COMPANY'
            />
            <div className={styles.info}>
              <table className={styles.info__table}>
                <tbody>
                  <tr>
                    <th>会社名</th>
                    <td>MIRAI SAKE COMPANY株式会社</td>
                  </tr>
                  <tr>
                    <th>本社オフィス</th>
                    <td>東京都台東区蔵前３丁目９－３臼井ビル５階</td>
                  </tr>
                  <tr>
                    <th>設立</th>
                    <td>2013年9月</td>
                  </tr>
                  <tr>
                    <th>資本金</th>
                    <td>1億670万円（2020年10月1日現在）</td>
                  </tr>
                  <tr>
                    <th>役員</th>
                    <td>代表取締役CEO 山本 祐也<br />
                      取締役 寺田 祐貴</td>
                  </tr>
                  <tr>
                    <th>グループ会社</th>
                    <td>株式会社未来酒店<br />YUMMY SAKE株式会社</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className={styles.company__map}>
          <iframe title="googlemap" ref={map} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6479.719427296479!2d139.79014976659977!3d35.70506967090645!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb86e0ceb464230a5!2sMIRAI%20SAKE%20COMPANY!5e0!3m2!1sja!2sjp!4v1613292541022!5m2!1sja!2sjp" width="800" frameBorder="0" allowFullScreen="" aria-hidden="false"></iframe>
        </div>
      </main>
    </Layout>
  )
}

export default CompanyPage
